
export const Empresas = {
    Simpress: "27d8028f-692c-4c93-be78-d5aaf44868fe",
    Embrart: "6201e8f5-9f67-40b0-a2df-13e5049f8e4a",
    BRT: "84c962de-26c5-43f4-aeae-22b4e4022a43",
    Petrobahia: "6201e8f5-9f67-40b0-a2df-13e5049f8e4a",
    POC: "95e6b155-8152-4c3c-8430-bef3333be2aa",
    Brf: "f5ad420e-2153-41d5-b2e8-9224d3f50b72",
    Triunfante: "0bc14ff3-6013-4eff-bb0f-63a6eef66836"
    // Adicione mais empresas conforme necessário
};